








import Component from 'vue-class-component';
import Vue from 'vue';
import {Action} from 'vuex-class';
import {QuizActions} from '@/modules/quiz/store/actions';
import QuizModule from '@/modules/quiz';

@Component({
  metaInfo: {
    title: 'Quiz'
  },
})
export default class Quiz extends Vue {
  @Action(QuizActions.GET_QUIZZES, {namespace: QuizModule.namespace}) getQuizzes: () => void;

  mounted(){
    this.getQuizzes();
  }
}
